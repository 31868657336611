import * as React from "react"
import { graphql } from 'gatsby';

import NotFound from 'pages/404';

import DataProvider from 'providers/data';

import Layout from 'templates/Layout';
import Content from 'templates/Content';
import Engagement from "components/academic-collaboration/Engagement";

const AcademicCollab = (props) => {
	const { edges } = props.data.allContentPage;
	const nodes = edges.map(({ node }) => node);
	let page = nodes[0];
	if ((props.providerResponse.data.length > 0 || !!page !== false) && props.providerStatusText === 'LOADED') {
		page = props.providerResponse.data[0];
	}
	if (!!page === false && props.providerStatus >= 2) return <NotFound />; // We only go to a page not found after the API loads or has an error and there's no page found.
	if (props.providerStatus < 2) { // This prevents any flash of content until the API is loaded -- but if the API takes a while to respond the effect would be the page loads slower. You could also use state to reveal content with a transition
		return null;
	}

	return (
		<div className={`loadWrap ${props.providerStatus >= 2 ? 'loaded' : ''}`}>
			{ props.providerStatus >= 2 &&
				<Layout showContactForm={page?.showContactForm} showNewsletterSignUp={page?.showNewsletterSignUp} hoverboards={page?.hoverboards}>
					<section className="largeContentBlock">
						<Content {...page} />
						{ page.studentImage &&
							<div className="grid-container">
								<div className="grid-x grid-padding-x align-center">
									<div className="cell small-12">
										<img src={page.studentImage} alt="" />
									</div>
								</div>
							</div>
						}
						<Engagement title={page.engagementTitle} content={page.engagementContent} image={page.engagementImage} />
					</section>
				</Layout>
			}
		</div>
	)
}

const getData = (props) => <DataProvider liveRefresh={props.params.liveRefresh} type="ContentPage" apiParams={{ uri: props.location.pathname }}><AcademicCollab {...props} /></DataProvider>
export default getData;

export const query = graphql`
	query {
	  allContentPage(
	    filter: {uri: {eq: "/academic-collaboration/"}, approved: {eq: true}, archived: {eq: false}, deleted: {eq: false}}
	  ) {
		edges {
			node {
			  pageTitle
			  meta
			  content {
				main
			  }
			  showContactForm
			  showNewsletterSignUp
			  headlineBody
			  headlineTitle
			  headlineImage
			  headlineType
			  hoverboards
			  engagementTitle
			  engagementContent
			  engagementImage
			  studentImage
			  solutions
			}
		  }
		}
	}
`