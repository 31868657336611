import * as React from "react"
import zparse from 'helpers/zparse';

const Engagement = ({title, content, image}) => {
    return (
        <section id="engagement">
            <div className="grid-container">
                <div className="grid-x grid-padding-x align-center">
                    <div className="cell small-12">
                        <h2>{title}</h2>
                    </div>
                    <div className="cell small-12 medium-10 flx">
                        <img src={image} alt="" />
                        <div>
                            {zparse(content)}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Engagement;